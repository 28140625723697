import classNames from "classnames";

export default function Button({
  children,
  disabled,
  className,
  onClick,
  groupHover,
  withSparkles,
  secondary,
  type = "button",
  size = "normal",
  basic,
  spinner,
  ...rest
}) {
  return (
    <div
      className={classNames("inline-flex rounded-md shadow-sm", className)}
      {...rest}
    >
      <button
        type={type}
        className={classNames(
          "eventive__button inline-flex items-center border border-transparent font-medium rounded-md transition ease-in-out duration-150 focus:outline-none",
          size === "huge"
            ? "px-6 py-3 text-base leading-6"
            : size === "large"
            ? "px-4 py-2 text-base leading-6"
            : "px-4 py-2 text-sm leading-5",
          secondary
            ? `border-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 bg-transparent hover:bg-indigo-500`
            : basic
            ? disabled
              ? "eventive__button__disabled bg-always-gray-50 cursor-not-allowed select-none text-always-gray-300"
              : `bg-always-white ${
                  groupHover ? "group-" : ""
                }hover:bg-always-gray-100 focus:border-gray-300 focus:shadow-outline-gray active:bg-always-gray-200 text-always-gray-700`
            : disabled
            ? "eventive__button__disabled bg-indigo-400 cursor-not-allowed text-always-white select-none"
            : `bg-indigo-600 text-always-white ${
                groupHover ? "group-" : ""
              }hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700`
        )}
        disabled={disabled}
        onClick={!disabled ? onClick : undefined}
      >
        {spinner ? (
          <svg
            width="16"
            height="16"
            viewBox="0 0 42 42"
            stroke="currentColor"
            className="mr-2"
          >
            <g fill="none" fill-rule="evenodd">
              <g transform="translate(1 1)" stroke-width="6">
                <circle stroke-opacity=".5" cx="20" cy="20" r="18"></circle>
                <path d="M38 20c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 20 20"
                    to="360 20 20"
                    dur="1s"
                    repeatCount="indefinite"
                  ></animateTransform>
                </path>
              </g>
            </g>
          </svg>
        ) : null}
        {children}
        {withSparkles ? (
          <svg
            className="ml-3 -mr-1 h-5 w-5"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              d="M5 3V7M3 5H7M6 17V21M4 19H8M13 3L15.2857 9.85714L21 12L15.2857 14.1429L13 21L10.7143 14.1429L5 12L10.7143 9.85714L13 3Z"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : null}
      </button>
    </div>
  );
}

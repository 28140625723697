import { useState, useEffect, useRef } from "react";
import uaParser from "ua-parser-js";

export function useStickyResult(value) {
  const val = useRef();
  if (value !== undefined) val.current = value;
  return val.current;
}

const ua = typeof window === "object" && uaParser();
export const useUserAgent = () => ua;
export function _performSecurityCheck() {
  if (!ua) {
    return { isExtremeSecurityOkay: true };
  }

  const os = ua.os.name;
  const goalBrowser =
    os === "Mac OS"
      ? "Safari"
      : os.includes("Windows")
      ? "Edge"
      : os === "Android" || os === "Chromium OS"
      ? "Chrome"
      : os === "iOS"
      ? "Mobile Safari"
      : "another device";
  const currentBrowser = ua.browser.name;

  return {
    isExtremeSecurityOkay: currentBrowser === goalBrowser,
    blockedExtremeSecurityBrowser: currentBrowser,
    recommendedExtremeSecurityBrowser: goalBrowser
  };
}
export function useSecurityCheck() {
  const [ready, setReady] = useState(false);
  const isClient = typeof window === "object";
  useEffect(() => {
    if (isClient) {
      setReady(true);
    }
  }, [isClient]);

  if (!ready) {
    return { isExtremeSecurityOkay: true };
  }

  return _performSecurityCheck();
}

export function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export function useLocalStorage(key, initialValue, { oneTimeOnly } = {}) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = value => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      if (!oneTimeOnly) {
        setStoredValue(valueToStore);
      }
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  const removeValue = () => {
    try {
      if (!oneTimeOnly) {
        setStoredValue(initialValue);
      }
      window.localStorage.removeItem(key);
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue, removeValue];
}
